<template>
  <el-dialog
    :title="$t('fasongxiaoxi')"
    :visible.sync="visible"
    center
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="closeassetsdialog"
    @open="open"
    :before-close="handleClose"
  >
    <div class="has" v-if="total !== 0">
    </div>
<!--    <div v-else>{{ $t('yh.gzhxwzc') }}</div> -->
    <el-divider></el-divider>
    <el-form
      ref="frozenform"
      :model="frozenform"
      :rules="formrules"
      label-width="120px"
    >
      <el-form-item :label="$t('yh.xinxi')" prop="reason">
        <el-input v-model="frozenform.reason"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click.native="submit">{{
        $t('yh.fasong')
      }}</el-button>
      <el-button @click="handleClose">{{ $t('quxiao') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      total: 0,
      product: [],
      // 拥有的资产对象
      hasassets: {
        contract: 0,
        legal_tender: 0,
        defi: 0
      },
      // 冻结解冻资产数据对象
      frozenform: {
        uid: '',
        content: ''
      },
      // 表单验证对象
      formrules: {
        content: [{ required: true, message: '请填写消息', trigger: 'blur' }]
      }
    }
  },
  props: ['visible', 'uid'],
  methods: {
    handleClose() {
      this.$emit('close')
    },
    // 打开弹窗请求数据
    async open() {
      this.frozenform.uid = this.uid
    },
    // 关闭asset弹窗
    closeassetsdialog() {
      this.$refs.frozenform.resetFields()
      this.assetsdialogvisible = false
    },
    submit() {
      this.$refs.frozenform.validate(async valid => {
        if (!valid) return false
        const { data } = await this.$http.post(
          '/admin/user/sendMsg',
          this.frozenform
        )
        if (data) {
          if (data.code === 200) {
            this.total = +this.total + this.frozenform.num + 0
            this.$message.success(this.$t('chenggong'))
            this.handleClose()
          } else {
            this.$message.error(this.getlang(data.msg))
          }
        }
      })
    }
  }
}
</script>
